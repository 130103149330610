.table-schema {
    display: grid;
    grid-template-columns: repeat(20, minmax(0, 1fr));
    grid-template-rows: repeat(23, minmax(0, 1fr));
    grid-template-areas: '. table16 table16 . table15 table15 . table14 table14 table14 table14 . table13 table13 table13 table13 . table8 table8 .'
        '. table16 table16 . table15 table15 . table14 table14 table14 table14 . table13 table13 table13 table13 . table8 table8 .'
        '. table16 table16 . table15 table15 . table14 table14 table14 table14 . table13 table13 table13 table13 . . . .'
        '. table16 table16 . table15 table15 . . . . . . . . . . . table7 table7 .'
        '. table16 table16 . table15 table15 . . . . . . . . . . . table7 table7 .'
        '. . . . . . . . . . . . . . . . . . . .'
        'table17 table17 table17 . table18 table18 . table25 table25 . table19 table19 . table12 table12 table12 . table6 table6 .'
        'table17 table17 table17 . table18 table18 . table25 table25 . table19 table19 . table12 table12 table12 . table6 table6 .'
        'table17 table17 table17 . table18 table18 . table25 table25 . table19 table19 . table12 table12 table12 . . . .'
        '. . . . . . . . . . . . . . . . . table5 table5 .'
        '. . . . . . . . . . . . . . . . . table5 table5 .'
        '. table22 table22 . table23 table23 . table24 table24 . . . . . . . . . . .'
        '. table22 table22 . table23 table23 . table24 table24 . . . . table11 table11 . . table4 table4 .'
        '. . . . . . . . . . . . . . . . . . . .'
        '. . . . . . . . . . . . . . . . . . . .'
        '. . . . . . . . . . . table10 table10 . . . . table3 table3 .'
        '. table20 table20 table20 table20 . table21 table21 table21 . . table10 table10 . . . . table3 table3 .'
        '. table20 table20 table20 table20 . table21 table21 table21 . . . . . . . . . . .'
        '. table20 table20 table20 table20 . table21 table21 table21 . . . table9 table9 . . . table2 table2 .'
        '. table20 table20 table20 table20 . table21 table21 table21 . . . . . . . . . . .'
        '. table20 table20 table20 table20 . . . . . . . . . . . . . . .'
        '. . . . . . . . . . . . . table26 table26 . . table1 table1 .'
    ;
    column-gap: 8px;
    row-gap: 8px;
    padding: 45px 18px;
    width: 100%;
    /* max-width: 936px; */
    height: calc(100vh - 20px);
    box-sizing: border-box;

    border-radius: 20px;
    border: 1px solid #3C3C4A;

    pointer-events: none;
    user-select: none;
    overflow: hidden;
}

.table__box {
    width: 100%;
    max-width: 288px;
    /* min-width: 134px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #7D808F;
    text-align: center;
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    /* 118.182% */
    text-transform: uppercase;
    border-radius: 10px;
    /* border: 3px solid #8A8AA3; */
    background-color: #27272E;

    padding: 24px 12px;
    box-sizing: border-box;
    position: relative;
    pointer-events: none;

    transition: all 1.5 linear;
}

.table__box__numbers {
    transition: color 1.5 linear;
    color: #7D808F;
    text-align: center;
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    /* 118.182% */
    text-transform: uppercase;
}

.table__box__icons {
    display: flex;
    justify-content: start;
    /* width: 100%; */
    gap: 4px;

    position: absolute;
    top: 8px;
    right: 8px;
}

.table__box__icons__img {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -20px;
    right: -20px;
}

@media (max-width:850px) {
    .table-schema {
        display: none;
    }
}