.swipeable-card__box-action {
    position: absolute;
    right: 0px;
    width: 82px;
    left: 342px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.swipeable-card__action {

    border-radius: 16px;
    background: #25252B;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #8A8AA3;
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    width: 77px;
}

.swipeable-card {
    overflow: hidden;
}

.swipeable-card__item {
    transition: all 0.1s linear;
    display: flex;
    height: fit-content !important;
    box-sizing: border-box !important;
    height: 106px !important;
    width: 420px;
}

.swipeable-card__item-inner {
    background: #fff;
    padding: 10px 20px;
    position: relative;
    z-index: 2;
    transition: all 0.1s linear;

    padding: 24px 20px;
    height: fit-content !important;
    box-sizing: border-box !important;
    height: 106px !important;

    width: 340px;
    border-radius: 16px !important;
    background: rgba(37, 37, 43, 0.45);
    position: relative;
    z-index: 1;
}

.swipeable-card__task__dots {
    position: absolute;
    top: 0px;
    right: 12px;

    display: flex;
    align-items: start;
    justify-content: start;
    width: 40px;
    height: 100px;
}

.swipeable-card__task__dots-svg {
    width: 100%;
    margin-top: 56px;
}

.swipeable-card__task {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
    height: 100%;

}

.swipeable-card__task__call {
    color: #ABADB7;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
}

.swipeable-card__task__call-type {
    color: #FFF;

    font-family: "Manrope";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 118.182% */
    margin-top: 21px;

    pointer-events: none;
    user-select: none;
}

.swipeable-card__task__call-and-time {
    display: flex;
    /* justify-content: space-between; */
    gap: 8px;
    width: 220px;
    pointer-events: none;
    user-select: none;
}

.swipeable-card__task__time {
    color: #8A8AA3;

    font-family: "Manrope";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 111.111% */
    display: flex;
    align-items: center;
    gap: 8px;
    pointer-events: none;
    user-select: none;

    position: absolute;
    left:139px
}

.swipeable-card__task__time__timer-icon {
    margin-top: -3px;
}

.swipeable-card_empty {
    color: #777786;
    text-align: center;

    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 80% */

    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

@media (max-width: 850px) {
    .swipeable-card__item-inner{
        width: calc(100%);
    }

    .swipeable-card__item{
        width: 100%;
    }
    .swipeable-card__box-action{
        left: unset;
        right: -82px;
    }
}