.app {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 20px;

  position: fixed;
  left: 0;
  top: 0;
  padding: 10px;
  box-sizing: border-box;
  background: #0D0D0D;
}